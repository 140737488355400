import React, { useState, useRef } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Text,
    Textarea,
    Grid,
    GridItem,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import { Documento } from "../../types/types";
import PalavrasChave from "./PalavrasChave";
import fetchData from "../../utils/fetchData";
import config from "../../config";
import { useCustomToast } from "../../utils/toastUtils";
import LoadingOverlay from "../../utils/LoadingOverlay";


// Tipagem para as props do modal
interface DocumentoModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (data: Documento) => void;
    onDelete: (id: number) => void;
    documento?: Documento | null;
    action: "create" | "edit" | "delete";
    descritor: boolean;
}

// Dados iniciais para criar um novo documento
const initialData: Documento = {
    titulo: "",
    discente: "",
    orientador: "",
    resumo: "",
    graphData: {},
    anoPublicacao: new Date().getFullYear(),
    palavraChave: [],
    id_projeto: 0,
    id_documento: 0, // Mantém o campo
};

const DocumentoModal: React.FC<DocumentoModalProps> = ({
    isOpen,
    onClose,
    onSave,
    onDelete,
    documento,
    action,
    descritor,
}) => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Documento>({
        defaultValues: documento || initialData,
    });
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const cancelRef = useRef<HTMLButtonElement>(null);
    const toast = useCustomToast();
    const handleModalClose = () => {
        setIsConfirmDialogOpen(true);
    };
    const handleConfirmClose = () => {
        reset();
        onClose();
        setIsConfirmDialogOpen(false);
    };
    const handleCancelClose = () => {
        setIsConfirmDialogOpen(false);
    };
    const onSubmit = async (data: Documento) => {
        let savedDocumento: Documento = {
            id_documento: 0,
            id_projeto: data.id_projeto,
            titulo: "",
            discente: "",
            orientador: "",
            resumo: "",
            graphData: {},
            anoPublicacao: new Date().getFullYear(),
            palavraChave: [],
        };
        LoadingOverlay.addOverlay();
        if (action === "edit") {
            // Atualizar documento existente
            await fetchData(
                `${config.apiUrl}/documento/${data.id_documento}`,
                'patch',
                toast,
                data
            );
            savedDocumento = { ...data };
        } else if (action === "create") {
            // Criar novo documento
            const newDocumento = await fetchData(
                `${config.apiUrl}/documento`,
                'post',
                toast,
                data
            );
            savedDocumento = { ...newDocumento };
        } else if (action === "delete") {
            await fetchData(
                `${config.apiUrl}/documento/${data.id_documento}`,
                'delete',
                toast,
                data
            );
            savedDocumento = { ...data };
        }
        LoadingOverlay.removeOverlay();
        onSave(savedDocumento);
        reset();
        onClose();
    };
    return (
        <>
            <Modal isOpen={isOpen} onClose={handleModalClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="70%">
                    <ModalHeader>
                        {action === "create" && "Criar Documento"}
                        {action === "edit" && "Editar Documento"}
                        {action === "delete" && "Excluir Documento"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {action === "delete" ? (
                            <>
                                <Text>Tem certeza de que deseja excluir o documento?</Text>
                                <Text>ID: {documento?.id_documento}</Text>
                                <Text>Título: {documento?.titulo}</Text>
                            </>
                        ) : (
                            <Grid templateColumns="70% 30%" gap={4}>
                                <GridItem colSpan={1}>
                                    <FormControl isInvalid={!!errors.id_projeto} isRequired>
                                        <FormLabel>ID do Projeto</FormLabel>
                                        <Controller
                                            name="id_projeto"
                                            control={control}
                                            rules={{
                                                required: "O ID do projeto é obrigatório.",
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    isReadOnly
                                                    placeholder="ID do projeto"
                                                    value={field.value ?? ""} // Substitui null por string vazia
                                                />
                                            )}
                                        />
                                        {errors.id_projeto && (
                                            <Text color="red.500">{errors.id_projeto.message}</Text>
                                        )}
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.titulo} isRequired>
                                        <FormLabel>Título</FormLabel>
                                        <Controller
                                            name="titulo"
                                            control={control}
                                            rules={{
                                                required: "O título é obrigatório.",
                                            }}
                                            render={({ field }) => (
                                                <Input {...field} placeholder="Título do documento" />
                                            )}
                                        />
                                        {errors.titulo && (
                                            <Text color="red.500">{errors.titulo.message}</Text>
                                        )}
                                    </FormControl>
                                    {!descritor && (
                                        <FormControl isInvalid={!!errors.discente} isRequired mt={4}>
                                            <FormLabel>Discente</FormLabel>
                                            <Controller
                                                name="discente"
                                                control={control}
                                                rules={{
                                                    required: "O discente é obrigatório.",
                                                }}
                                                render={({ field }) => (
                                                    <Input {...field} placeholder="Discente do documento" />
                                                )}
                                            />
                                            {errors.discente && (
                                                <Text color="red.500">{errors.discente.message}</Text>
                                            )}
                                        </FormControl>
                                    )}
                                    {!descritor && (
                                        <FormControl isInvalid={!!errors.orientador} isRequired mt={4}>
                                            <FormLabel>Orientador</FormLabel>
                                            <Controller
                                                name="orientador"
                                                control={control}
                                                rules={{
                                                    required: "O orientador é obrigatório.",
                                                }}
                                                render={({ field }) => (
                                                    <Input {...field} placeholder="Orientador do documento" />
                                                )}
                                            />
                                            {errors.orientador && (
                                                <Text color="red.500">{errors.orientador.message}</Text>
                                            )}
                                        </FormControl>
                                    )}
                                    <FormControl isInvalid={!!errors.resumo} isRequired mt={4}>
                                        <FormLabel>Resumo</FormLabel>
                                        <Controller
                                            name="resumo"
                                            control={control}
                                            rules={{
                                                required: "O resumo é obrigatório.",
                                            }}
                                            render={({ field }) => (
                                                <Textarea {...field} placeholder="Resumo do documento" rows={12} />
                                            )}
                                        />
                                        {errors.resumo && (
                                            <Text color="red.500">{errors.resumo.message}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl isInvalid={!!errors.anoPublicacao} isRequired mt={4}>
                                        <FormLabel>Ano de Publicação</FormLabel>
                                        <Controller
                                            name="anoPublicacao"
                                            control={control}
                                            rules={{
                                                required: "O ano de publicação é obrigatório.",
                                                min: { value: 1900, message: "Ano inválido." },
                                                max: {
                                                    value: new Date().getFullYear(),
                                                    message: "Ano inválido.",
                                                },
                                            }}
                                            render={({ field }) => (
                                                <Input {...field} type="number" placeholder="Ano de publicação" />
                                            )}
                                        />
                                        {errors.anoPublicacao && (
                                            <Text color="red.500">{errors.anoPublicacao.message}</Text>
                                        )}
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.palavraChave} mt={4}>
                                        <Controller
                                            name="palavraChave"
                                            control={control}
                                            render={({ field }) => (
                                                <PalavrasChave
                                                    palavrasIniciais={
                                                        Array.isArray(field.value)
                                                            ? field.value // Se já for um array, usa diretamente
                                                            : (field.value || "") // Se for string ou indefinido, converte para string
                                                                .split(/[.,;]/) // Divide usando delimitadores "," ou ";"
                                                                .map(palavra => palavra) // Remove espaços em branco extras
                                                    }
                                                    isEditing={action === "edit" || action === "create"} // Permite edição apenas para criar ou editar
                                                    onPalavrasChange={(novasPalavras) => field.onChange(novasPalavras)} // Atualiza o valor no Controller
                                                    tituloAtributo={"Palavras-Chave"}
                                                    soLista={false}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </GridItem>
                            </Grid>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {action === "delete" ? (
                            <>
                                <Button
                                    colorScheme="red"
                                    mr={3}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Excluir
                                </Button>
                                <Button onClick={onClose}>Cancelar</Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    colorScheme="teal"
                                    mr={3}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {action === "edit" ? "Salvar" : "Criar"}
                                </Button>
                                <Button onClick={handleModalClose}>Cancelar</Button>
                            </>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={handleCancelClose}
                onConfirm={handleConfirmClose}
                cancelRef={cancelRef}
                header="Confirmar ação"
                body="Deseja realmente fechar sem salvar?"
            />
        </>
    );
};

export default DocumentoModal;


