import React, { useState, useEffect } from 'react';
import { FormControl, FormLabel, Input, Flex, Tag, TagLabel, TagCloseButton, useToast } from '@chakra-ui/react';

interface PalavrasChaveProps {
  palavrasIniciais: string | string[]; // Aceita string ou vetor
  isEditing: boolean;
  tituloAtributo: string;
  soLista: boolean; // Se `true`, exibe apenas a lista de palavras-chave
  onPalavrasChange: (novasPalavras: string[]) => void; // Sempre envia vetor
}

const PalavrasChave: React.FC<PalavrasChaveProps> = ({
  palavrasIniciais,
  isEditing,
  onPalavrasChange,
  tituloAtributo,
  soLista,
}) => {
  // Função para normalizar o array de palavras-chave
  const normalizarPalavras = (palavras: string[]): string[] => {
    return palavras
      .flatMap((palavra) => palavra.split(/[.,;]/)) // Divide pelos delimitadores ".", ",", ";"
      .map((p) => p.trim()) // Remove espaços extras
      .filter((p) => p !== ''); // Remove strings vazias
  };

  const [palavrasChave, setPalavrasChave] = useState<string[]>(() => {
    if (typeof palavrasIniciais === 'string') {
      return normalizarPalavras(palavrasIniciais.split(/[.,;]/));
    } else if (Array.isArray(palavrasIniciais)) {
      return normalizarPalavras(palavrasIniciais);
    }
    return [];
  });

  const [novaPalavraChave, setNovaPalavraChave] = useState('');
  const toast = useToast();

  // Atualiza o estado inicial apenas se `palavrasIniciais` mudar
  useEffect(() => {
    if (typeof palavrasIniciais === 'string') {
      const palavrasComoVetor = normalizarPalavras(palavrasIniciais.split(/[.,;]/));
      if (JSON.stringify(palavrasChave) !== JSON.stringify(palavrasComoVetor)) {
        setPalavrasChave(palavrasComoVetor);
      }
    } else if (Array.isArray(palavrasIniciais)) {
      const palavrasNormalizadas = normalizarPalavras(palavrasIniciais);
      if (JSON.stringify(palavrasChave) !== JSON.stringify(palavrasNormalizadas)) {
        setPalavrasChave(palavrasNormalizadas);
      }
    }
  }, [palavrasIniciais]);

  // Notifica alterações para o componente pai como vetor
  useEffect(() => {
    onPalavrasChange(palavrasChave);
  }, [palavrasChave]);

  const handleAddPalavraChave = () => {
    if (novaPalavraChave.trim() !== '') {
      setPalavrasChave([...palavrasChave, novaPalavraChave.trim()]);
      setNovaPalavraChave('');
    } else {
      toast({
        title: 'Erro',
        description: 'A palavra-chave não pode estar vazia.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemovePalavraChave = (index: number) => {
    setPalavrasChave(palavrasChave.filter((_, i) => i !== index));
  };

  return (
    <FormControl>
      {!soLista && (
        <>
          <FormLabel>{tituloAtributo}</FormLabel>
          <Input
            placeholder="Digite uma palavra-chave e pressione Enter"
            value={novaPalavraChave}
            onChange={(e) => setNovaPalavraChave(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && isEditing) {
                e.preventDefault();
                handleAddPalavraChave();
              }
            }}
            isReadOnly={!isEditing}
            border="1px solid black"
          />
        </>
      )}
      <Flex mt={2} wrap="wrap">
        {palavrasChave.map((palavra, index) => (
          <Tag key={index} borderRadius="full" variant="solid" colorScheme="teal" mr={2} mb={2}>
            <TagLabel>{palavra}</TagLabel>
            {isEditing && !soLista && <TagCloseButton onClick={() => handleRemovePalavraChave(index)} />}
          </Tag>
        ))}
      </Flex>
    </FormControl>
  );
};

export default PalavrasChave;
