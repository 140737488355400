import { Tooltip, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface TooltipButtonProps {
  label: string; // Texto do tooltip
  icon: IconProp; // Ícone do FontAwesome
  onClick: () => void; // Função executada no clique do botão
  colorScheme?: string; // Esquema de cores do botão (ex.: "blue", "green")
  size?: string; // Tamanho do botão (ex.: "sm", "md", "lg")
  tooltipBg?: string; // Cor de fundo do tooltip
  tooltipPlacement?: "top" | "bottom" | "left" | "right"; // Posição do tooltip
  tooltipColor?: string; // Cor do texto do tooltip
  variant?: "solid" | "outline" | "ghost"; // Variante do botão
  marginRight?: string | number; // Espaçamento à direita do botão
}

const TooltipButton: React.FC<TooltipButtonProps> = ({
  label,
  icon,
  onClick,
  colorScheme = "blue",
  size = "md",
  tooltipBg = "gray.700",
  tooltipPlacement = "top",
  tooltipColor = "white",
  variant = "solid",
  marginRight = "4px", // Espaçamento padrão: 0
}) => {
  return (
    <Tooltip
      label={label}
      fontSize="sm"
      bg={tooltipBg}
      color={tooltipColor}
      placement={tooltipPlacement}
      hasArrow
    >
      <Button
        colorScheme={colorScheme}
        onClick={onClick}
        size={size}
        variant={variant}
        style={{ marginRight }} // Adiciona a margem diretamente no botão
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    </Tooltip>
  );
};

export default TooltipButton;
