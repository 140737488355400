import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import cloud, { Word as CloudWord } from 'd3-cloud';
import { Box, Button } from '@chakra-ui/react';

interface WordCloudComponentProps {
  words: CloudWord[];
}

const WordCloudComponent: React.FC<WordCloudComponentProps> = ({ words }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const boxRef = useRef<HTMLDivElement | null>(null); // Referência para o Box

  useEffect(() => {
    const width = boxRef.current?.clientWidth || 500; // Calcula largura dinamicamente
    const height = boxRef.current?.clientHeight || 500; // Calcula altura dinamicamente

    console.log('palavras recebidas ', words);

    d3.select(svgRef.current).selectAll('*').remove();

    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    const layout = cloud<CloudWord>()
      .size([width, height])
      .words(words)
      .padding(5)
      .rotate(0)
      .font('Arial')
      .fontSize((d) => Math.max(10, d.size * 2))
      .on('end', draw);

    layout.start();

    function draw(words: CloudWord[]) {
      const svg = d3.select(svgRef.current)
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

      svg
        .selectAll('text')
        .data(words)
        .enter()
        .append('text')
        .style('font-size', (d) => `${d.size}px`)
        .style('font-family', 'Arial')
        .style('fill', (d, i) => colorScale(i.toString()))
        .attr('text-anchor', 'middle')
        .attr('transform', (d) => `translate(${d.x}, ${d.y}) rotate(${d.rotate})`)
        .text((d) => d.text);
    }
  }, [words]);

  return (
    <Box ref={boxRef} width="100%" height="600px"  p={4}>
      <Button
        onClick={() => {
          const svgElement = svgRef.current;
          if (svgElement) {
            const serializer = new XMLSerializer();
            const svgData = serializer.serializeToString(svgElement);
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(svgBlob);

            const img = new Image();
            img.onload = () => {
              canvas.width = svgElement.clientWidth;
              canvas.height = svgElement.clientHeight;
              context?.drawImage(img, 0, 0);
              URL.revokeObjectURL(url);

              canvas.toBlob((blob) => {
                if (blob) {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = 'word_cloud.png';
                  link.click();
                }
              });
            };
            img.src = url;
          }
        }}
        style={{
          padding: '10px 20px',
          backgroundColor: '#3182ce',
          color: '#fff',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          marginBottom: '10px',
        }}
      >
        Baixar Imagem da Nuvem
      </Button>

      <svg ref={svgRef}></svg>
    </Box>
  );
};

export default WordCloudComponent;
