const LoadingOverlay = {
    overlayId: 'loading-overlay',
  
    addOverlay() {
      // Verifica se o overlay já existe para evitar duplicação
      if (document.getElementById(this.overlayId)) return;
  
      const overlay = document.createElement('div');
      overlay.id = this.overlayId;
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.zIndex = '9999';
      overlay.style.display = 'flex';
      overlay.style.justifyContent = 'center';
      overlay.style.alignItems = 'center';
      overlay.style.backgroundColor =  'transparent' //'rgba(255, 255, 255, 0.7)'; // Fundo translúcido
      overlay.style.pointerEvents = 'none'; // Garante que cliques não passem pelo overlay
  
      const spinner = document.createElement('div');
      spinner.style.border = '4px solid rgba(255, 255, 255, 0.3)';
      spinner.style.borderTop = '4px solid black'; // Cor visível no spinner
      spinner.style.borderRadius = '50%';
      spinner.style.width = '40px';
      spinner.style.height = '40px';
      spinner.style.animation = 'spin 1s linear infinite';
  
      overlay.appendChild(spinner);
      document.body.appendChild(overlay);
  
      // Adiciona a animação para o spinner
      if (!document.getElementById('spinner-style')) {
        const style = document.createElement('style');
        style.id = 'spinner-style';
        style.type = 'text/css';
        style.innerHTML = `
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `;
        document.head.appendChild(style);
      }
    },
  
    removeOverlay() {
      const overlay = document.getElementById(this.overlayId);
      if (overlay) {
        overlay.remove();
      }
    },
  };
  
  export default LoadingOverlay;
  