import React, { useState, useRef } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Text,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { Projeto } from "../../types/types";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import LoadingOverlay from "../../utils/LoadingOverlay";
import config from "../../config";
import { useCustomToast } from "../../utils/toastUtils";
import fetchData from "../../utils/fetchData";

// Props Tipadas para o Modal
interface ProjetoModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (data: Projeto) => void;
    onDelete: () => void;
    projeto?: Projeto | null;
    action: "create" | "edit" | "delete";
}

// Dados iniciais para criar um novo projeto
const initialData: Projeto = {
    titulo: "",
    ano_inicial: new Date().getFullYear(),
    ano_final: new Date().getFullYear(),
    descritor: false,
    usuario: false,
    id_projeto: 0,
    documentos: []
};

const ProjetoModal: React.FC<ProjetoModalProps> = ({
    isOpen,
    onClose,
    onSave,
    onDelete,
    projeto,
    action,
}) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<Omit<Projeto, "documentos">>({
        defaultValues: projeto || initialData,
    });
    const toast = useCustomToast();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false); // Estado para o ConfirmDialog
    const cancelRef = useRef<HTMLButtonElement>(null); // Referência para o botão "Cancelar"

    const handleModalClose = () => {
        setIsConfirmDialogOpen(true); // Abre o ConfirmDialog
    };

    const handleConfirmClose = () => {
        reset(); // Reseta o formulário
        onClose(); // Fecha o modal principal
        setIsConfirmDialogOpen(false); // Fecha o ConfirmDialog
    };

    const handleCancelClose = () => {
        setIsConfirmDialogOpen(false); // Fecha o ConfirmDialog
    };

    const onSubmit = async (data: Omit<Projeto, "documentos">) => {
        LoadingOverlay.addOverlay();
    
        // Extrair os campos necessários
        const { titulo, ano_inicial, ano_final, descritor, usuario, id_projeto } = data;
        const dataToSend = { titulo, ano_inicial, ano_final, descritor, usuario, id_projeto };
    
        // Definir o endpoint e o método com base na ação
        let endpoint = `${config.apiUrl}/projetos`;
        let method: 'post' | 'patch' | 'delete' = 'post';
    
        if (action === 'edit') {
            endpoint = `${config.apiUrl}/projetos/${data.id_projeto}`;
            method = 'patch';
        } else if (action === 'delete') {
            endpoint = `${config.apiUrl}/projetos/${data.id_projeto}`;
            method = 'delete';
        }
    
        let result;
    
        // Executar a chamada de API com base no método
        if (method === 'delete') {
            await fetchData(endpoint, method, toast);
            onDelete();
            console.log(`Projeto com ID ${data.id_projeto} excluído`);
        } else {
            result = await fetchData(endpoint, method, toast, dataToSend);
            onSave(result); // Apenas para criação ou edição
        }
    
        // Finalizar a operação
        LoadingOverlay.removeOverlay();
        reset();
        onClose();
    };
    
    return (
        <>
            <Modal isOpen={isOpen} onClose={handleModalClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {action === "create" && "Criar Projeto"}
                        {action === "edit" && "Editar Projeto"}
                        {action === "delete" && "Excluir Projeto"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {action === "delete" ? (
                            <>
                                <Text>Tem certeza de que deseja excluir o projeto?</Text>
                                <Text>ID: {projeto?.id_projeto}</Text>
                                <Text>Título: {projeto?.titulo}</Text>
                            </>
                        ) : (
                            <VStack spacing={4}>
                                <FormControl isInvalid={!!errors.titulo} isRequired>
                                    <FormLabel>Título</FormLabel>
                                    <Controller
                                        name="titulo"
                                        control={control}
                                        rules={{
                                            required: "O título é obrigatório.",
                                            minLength: { value: 3, message: "O título deve ter pelo menos 3 caracteres." },
                                        }}
                                        render={({ field }) => (
                                            <Input {...field} placeholder="Título do projeto" />
                                        )}
                                    />
                                    {errors.titulo && <Text color="red.500">{errors.titulo.message}</Text>} {/* Verifique fechamento */}
                                </FormControl>
                                <FormControl isInvalid={!!errors.ano_inicial} isRequired>
                                    <FormLabel>Ano Inicial</FormLabel>
                                    <Controller
                                        name="ano_inicial"
                                        control={control}
                                        rules={{
                                            required: "O ano inicial é obrigatório.",
                                            min: { value: 1900, message: "O ano inicial deve ser maior ou igual a 1900." },
                                            max: { value: new Date().getFullYear(), message: "O ano inicial não pode ser no futuro." },
                                        }}
                                        render={({ field }) => (
                                            <Input {...field} type="number" placeholder="Ano inicial" />
                                        )}
                                    />
                                    {errors.ano_inicial && <Text color="red.500">{errors.ano_inicial.message}</Text>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.ano_final} isRequired>
                                    <FormLabel>Ano Final</FormLabel>
                                    <Controller
                                        name="ano_final"
                                        control={control}
                                        rules={{
                                            required: "O ano inicial é obrigatório.",
                                            min: { value: 1900, message: "O ano inicial deve ser maior ou igual a 1900." },
                                            max: { value: new Date().getFullYear(), message: "O ano inicial não pode ser no futuro." },
                                        }}
                                        render={({ field }) => (
                                            <Input {...field} type="number" placeholder="Ano final" />
                                        )}
                                    />
                                    {errors.ano_final && <Text color="red.500">{errors.ano_final.message}</Text>}
                                </FormControl>
                            </VStack>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {action === "delete" ? (
                            <>
                                <Button
                                    colorScheme="red"
                                    mr={3}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Excluir
                                </Button>
                                <Button onClick={onClose}>Cancelar</Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    colorScheme="teal"
                                    mr={3}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {action === "edit" ? "Salvar" : "Criar"}
                                </Button>
                                <Button onClick={handleModalClose}>Cancelar</Button>
                            </>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* ConfirmDialog para confirmação ao fechar sem salvar */}
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={handleCancelClose}
                onConfirm={handleConfirmClose}
                cancelRef={cancelRef}
                header="Confirmar ação"
                body="Deseja realmente fechar sem salvar?"
            />
        </>
    );
};

export default ProjetoModal;
