import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    VStack,
    Text,
    Spinner,
    Alert,
    AlertIcon,
    Progress,
} from '@chakra-ui/react';
import fetchData from '../../utils/fetchData';
import { useCustomToast } from '../../utils/toastUtils';
import config from '../../config';
// Inteface do modelo
interface Modelo {
    id_modelo: number;
    titulo: string;
}
//  Interface do 
interface SelectModelsProps {
    isOpen: boolean;
    onClose: () => void;
    id_projeto: number;
}
const SelectModels: React.FC<SelectModelsProps> = ({ isOpen, onClose, id_projeto }) => {
    const [modelos, setModelos] = useState<Modelo[]>([]);
    const [loading, setLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const toast = useCustomToast();
    useEffect(() => {
        const fetchModelos = async () => {
            const data = await fetchData(
                `${config.apiUrl}/modelos/por-convidado/1`,
                'get',
                toast
            );
            if (data) {
                setModelos(data); // Atualiza os modelos apenas se os dados forem retornados
            }
            setLoading(false); // Define o carregamento como concluído
        };
        fetchModelos();
    }, []);
    if (loading) {
        return <Spinner size="xl" />;
    }
    if (modelos.length === 0) {
        return (
            <Alert status="info">
                <AlertIcon />
                Nenhum modelo encontrado.
            </Alert>
        );
    }
    const handleModeloClick = async (id_modelo: number) => {
        try {
            setIsProcessing(true);
            setProgress(0);
            let dados = await fetchData(
                `${config.apiUrl}/projetos/list/${id_projeto}`,
                'get',
                toast
            );
            //
            if (!dados || !dados[0] || !dados[0].documentos) {
                throw new Error("Nenhum documento encontrado na resposta da API.");
            }
            const documentos = dados[0].documentos;
            const totalDocumentos = documentos.length;
            //
            for (let index = 0; index < totalDocumentos; index++) {
                const documento = documentos[index];
                const caminho = `${config.apiUrl}/process/classify/${id_modelo}/${documento.id_documento}/O`;
                // Atualiza a barra de progresso
                setProgress(((index + 1) / totalDocumentos) * 100);
                await fetchData(
                    caminho,
                    'get',
                    toast
                );
            }
            onClose(); // Fecha o modal
        } catch (error) {
            console.error('Erro ao processar modelo:', error);
        } finally {
            setIsProcessing(false);
        }
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl" closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Selecione um Modelo</ModalHeader>
                <ModalCloseButton isDisabled={isProcessing} /> {/* Desativa o botão fechar */}
                <ModalBody>
                    <VStack spacing={4} align="start" style={{ position: 'relative' }}>
                        {/* Barra de Progresso */}
                        {isProcessing && (
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <div
                                    style={{
                                        marginBottom: '10px',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        color: '#2B6CB0',
                                    }}
                                >
                                    🚀 Processando... {progress.toFixed(0)}%
                                </div>
                                <Progress
                                    value={progress}
                                    size="lg"
                                    colorScheme="blue"
                                    hasStripe
                                    isAnimated
                                    borderRadius="8px"
                                    boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
                                />
                                <div
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '14px',
                                        color: '#4A5568',
                                    }}
                                >
                                    Por favor, aguarde enquanto processamos os arquivos.
                                </div>
                            </div>
                        )}
                        {/* Lista de Modelos */}
                        {modelos.map((modelo) => (
                            <Box
                                key={modelo.id_modelo}
                                p={4}
                                borderWidth="1px"
                                borderRadius="md"
                                boxShadow="sm"
                                width="100%"
                                cursor={isProcessing ? 'not-allowed' : 'pointer'} // Altera o cursor
                                onClick={() => !isProcessing && handleModeloClick(modelo.id_modelo)} // Impede clique durante processamento
                            >
                                <Text fontSize="lg" fontWeight="bold">
                                    {modelo.titulo || 'Modelo sem título'}
                                </Text>
                            </Box>
                        ))}
                        {/* Overlay de Bloqueio */}
                        {isProcessing && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    zIndex: 10,
                                    cursor: 'not-allowed',
                                }}
                            />
                        )}
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SelectModels;
