import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Documento } from "../../types/types";

interface ExpandableRowProps {
  documentos: Documento[];
  selectedDocumentId: number | null; // ID do documento selecionado
  onDocumentSelect: (doc: Documento) => void;
}

const ExpandableRow: React.FC<ExpandableRowProps> = ({
  documentos,
  selectedDocumentId,
  onDocumentSelect,
}) => {
  return (
    <Box p={4} bg="gray.50" borderRadius="md" boxShadow="md">
      {documentos.map((doc) => (
        <Box
          key={doc.id_documento}
          mb={2}
          p={2}
          border="1px solid"
          borderColor={selectedDocumentId === doc.id_documento ? "blue.300" : "gray.200"}
          bg={selectedDocumentId === doc.id_documento ? "blue.50" : "white"}
          borderRadius="md"
          cursor="pointer"
          onClick={() => onDocumentSelect(doc)}
          _hover={{ bg: "gray.100" }}
        >
          <Text fontSize="md">
            {doc.titulo.length > 50 ? `${doc.titulo.substring(0, 50)}...` : doc.titulo}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default ExpandableRow;
