import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, ButtonGroup, Spinner } from '@chakra-ui/react';
import * as d3 from 'd3';
import axios from 'axios';
import config from '../config';

interface GroupedBarChartProps {
    id_modelo: number;
    id_projeto: number;
}

interface Document {
    id_documento: number;
    titulo: string;
    publicado: number;
    probability: number;
    normalizedSize: number; 
    [key: string]: number | string;
}

const GroupedBarChart: React.FC<GroupedBarChartProps> = ({ id_modelo, id_projeto }) => {
    const [documents, setDocuments] = useState<Document[]>([]);
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(true);
    const [activeYears, setActiveYears] = useState<string[]>([]);
    const [activeGoals, setActiveGoals] = useState<string[]>([]); // Inicialize como vazio
    const [chartType, setChartType] = useState<'ods' | 'year'>('ods'); // Tipo de gráfico atual
    const [isCounting, setIsCounting] = useState(true); // gráfico por contagem

    const chartElementRef = useRef<SVGSVGElement | null>(null);

    // Efeito para buscar os dados
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await axios.get(
                    `${config.apiUrl}/process/formattedresults/${id_modelo}/${id_projeto}/0`
                );
                const data = response.data.data.slice(1); // Ignorar o cabeçalho
                setDocuments(data as Document[]);

                // Gera os anos únicos como strings, mas não define `activeYears` inicialmente
                const years: string[] = Array.from(
                    new Set(data.map((doc: Document) => String(doc.publicado)))
                );

                // Gera os ODS únicos com base nas chaves do primeiro documento, mas mantém `activeGoals` vazio inicialmente
                const odsKeys: string[] =
                    data.length > 0
                        ? Object.keys(data[0]).filter(key => key.startsWith('ODS'))
                        : [];

                setActiveYears(years.sort((a, b) => a.localeCompare(b)));
                setActiveGoals(odsKeys.sort((a, b) => a.localeCompare(b)));

                // Opcional: forneça informações ao usuário sobre os anos e ODS disponíveis (não ativos)
                // console.log('Anos disponíveis:', years);
                // console.log('ODS disponíveis:', odsKeys);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDocuments();
    }, [id_modelo, id_projeto]);


    // Efeito para renderizar o gráfico após os dados serem carregados
    useEffect(() => {
        if (!loading && chartElementRef.current) {
            if (chartType === 'ods') {
                renderChartOds();
            } else {
                renderChartAno();
            }
        }
    }, [loading, chartType, isExpanded, isCounting]);
    //}, [loading, chartType , documents, isExpanded, activeYears, activeGoals, chartType, isCounting]);

    const renderChartOds = () => {
        // Verifica se o elemento SVG está disponível. Se não estiver, a função retorna sem fazer nada.
        if (!chartElementRef.current) return;

        // Seleciona o elemento SVG e limpa qualquer conteúdo anterior para desenhar um novo gráfico.
        const svgCanvas = d3.select(chartElementRef.current);
        svgCanvas.selectAll('*').remove();

        // Define os Objetivos de Desenvolvimento Sustentável (ODS) ativos.
        const sustainableDevelopmentGoals = activeGoals;

        // Extrai os anos únicos das publicações disponíveis no conjunto de documentos.
        const publicationYears = Array.from(new Set(documents.map(doc => String(doc.publicado))));

        // Processa os dados para o gráfico: combina cada ano de publicação com os ODS para gerar os valores das barras.
        const chartData = publicationYears.flatMap(publicationYear =>
            sustainableDevelopmentGoals.map(goal => {
                // Filtra os documentos que pertencem ao ano atual.
                const documentsByYear = documents.filter(doc => String(doc.publicado) === publicationYear);
                let goalValue = 0;

                // Calcula o valor das barras. Se `isCounting` for true, conta os documentos. Caso contrário, soma as probabilidades.
                if (isCounting) {
                    goalValue = documentsByYear.filter(doc => {
                        const goalData = doc[goal] as unknown as { normalizedSize: number };
                        return goalData?.normalizedSize > 0; // Verifica se a probabilidade é maior que zero.
                    }).length;
                } else {
                    goalValue = d3.sum(documentsByYear, doc => {
                        const goalData = doc[goal] as unknown as { normalizedSize: number };
                        return goalData?.normalizedSize || 0; // Soma as probabilidades.
                    });
                }

                // Retorna os dados formatados para cada ODS e ano.
                return {
                    goal,
                    year: String(publicationYear),
                    value: goalValue,
                };
            })
        );

        // Define as margens do gráfico.
        const chartMargins = { top: 20, right: 150, bottom: 80, left: 100 };

        // Calcula a largura e altura do gráfico, considerando as margens e o modo expandido.
        const chartWidth = (isExpanded ? 1200 : 900) - chartMargins.left - chartMargins.right;
        const chartHeight = (isExpanded ? 800 : 600) - chartMargins.top - chartMargins.bottom;

        // Configura a escala para o eixo X (ODS).
        const xAxisScaleGoals = d3.scaleBand()
            .domain(sustainableDevelopmentGoals)
            .rangeRound([0, chartWidth])
            .paddingInner(0.1);

        // Configura a escala para o agrupamento secundário no eixo X (anos).
        const xAxisScaleYears = d3.scaleBand()
            .domain(publicationYears)
            .rangeRound([0, xAxisScaleGoals.bandwidth()])
            .padding(0.05);

        // Configura a escala para o eixo Y, baseada nos valores máximos do conjunto de dados.
        const yAxisScale = d3.scaleLinear()
            .domain([0, d3.max(chartData, dataPoint => dataPoint.value) || 0])
            .nice()
            .rangeRound([chartHeight, 0]);

        // Define a escala de cores, atribuindo uma cor diferente para cada ano.
        const colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(publicationYears);

        // Adiciona um grupo ao SVG para posicionar o gráfico considerando as margens.
        const chartGroup = svgCanvas.append('g')
            .attr('transform', `translate(${chartMargins.left}, ${chartMargins.top})`);

        // Renderiza os grupos de barras (para cada ODS) e desenha as barras de cada ano dentro dos grupos.
        chartGroup.selectAll('g')
            .data(sustainableDevelopmentGoals)
            .join('g')
            .attr('transform', goal => `translate(${xAxisScaleGoals(goal) ?? 0},0)`)
            .selectAll('rect')
            .data(goal => publicationYears.map(publicationYear => {
                const value = chartData.find(item => item.goal === goal && item.year === publicationYear)?.value || 0;
                return { year: publicationYear, value, goal };
            }))
            .join('rect')
            .attr('x', dataPoint => xAxisScaleYears(dataPoint.year) ?? 0)
            .attr('y', dataPoint => yAxisScale(dataPoint.value))
            .attr('width', xAxisScaleYears.bandwidth())
            .attr('height', dataPoint => yAxisScale(0) - yAxisScale(dataPoint.value))
            .attr('fill', dataPoint => colorScale(dataPoint.year))
            .style('opacity', dataPoint =>
                activeYears.includes(dataPoint.year) && activeGoals.includes(dataPoint.goal) ? 1 : 0.3
            );

        // Cria o eixo X baseado na escala dos ODS.
        const xAxis = d3.axisBottom(xAxisScaleGoals).tickSizeOuter(0);

        // Cria o eixo Y baseado nos valores calculados.
        const yAxis = d3.axisLeft(yAxisScale).ticks(10);

        // Renderiza o eixo X na parte inferior do gráfico.
        chartGroup.append('g')
            .attr('transform', `translate(0,${chartHeight})`) // Posiciona o eixo X na parte inferior do gráfico.
            .call(xAxis) // Renderiza o eixo X baseado na escala configurada.
            .selectAll("text") // Seleciona todas as labels do eixo X.
            .style("text-anchor", "start") // Ajusta o alinhamento das labels para o início (ângulo de 45 graus requer isso).
            .attr("dx", "0.8em") // Move as labels horizontalmente para a direita.
            .attr("dy", "0.15em") // Move as labels verticalmente levemente para baixo.
            .attr("transform", "rotate(45)"); // Rotaciona as labels em 45 graus.


        // Renderiza o eixo Y na lateral esquerda do gráfico.
        chartGroup.append('g')
            .call(yAxis);

        // Adiciona o título ao eixo Y.
        chartGroup.append('text')
            .attr('transform', `rotate(-90)`)
            .attr('x', -chartHeight / 2)
            .attr('y', -chartMargins.left / 2)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('font-size', '14px')
            .text(isCounting ? 'Contagem' : 'Intensidade');

        // Adiciona o grupo de legendas à direita do gráfico.
        const legendGroup = svgCanvas.append('g')
            .attr('transform', `translate(${chartMargins.left + chartWidth + 30}, ${chartMargins.top})`);

        // Adiciona os retângulos coloridos da legenda (um para cada ano).
        legendGroup.selectAll('rect')
            .data(publicationYears)
            .join('rect')
            .attr('x', 0)
            .attr('y', (year, index) => index * 20)
            .attr('width', 15)
            .attr('height', 15)
            .attr('fill', year => colorScale(year))
            .style('cursor', 'pointer')
            .style('opacity', years => (activeYears.includes(years) ? 1 : 0.3))
            .on('click', (event, year) => toggleYearVisibility(String(year)));

        // Adiciona os textos das legendas ao lado dos retângulos coloridos.
        legendGroup.selectAll('text')
            .data(publicationYears)
            .join('text')
            .attr('x', 20)
            .attr('y', (year, index) => index * 20 + 12)
            .text(year => year)
            .attr('fill', '#000')
            .attr('font-size', '12px')
            .style('cursor', 'pointer')
            .style('opacity', years => (activeYears.includes(years) ? 1 : 0.3))
            .on('click', (event, year) => toggleYearVisibility(String(year)));
    };

    const renderChartAno = () => {
        // Verifica se o elemento SVG está disponível. Se não estiver, a função retorna sem fazer nada.
        if (!chartElementRef.current) return;

        // Seleciona o elemento SVG e limpa qualquer conteúdo anterior para desenhar um novo gráfico.
        const svgCanvas = d3.select(chartElementRef.current);
        svgCanvas.selectAll('*').remove();

        // Define os Objetivos de Desenvolvimento Sustentável (ODS) ativos.
        const sustainableDevelopmentGoals = activeGoals;

        // Extrai os anos únicos das publicações disponíveis no conjunto de documentos.
        const publicationYears = Array.from(new Set(documents.map(doc => String(doc.publicado))));

        // Processa os dados para o gráfico: combina cada ODS com os anos de publicação para gerar os valores das barras.
        const chartData = publicationYears.flatMap(publicationYear =>
            sustainableDevelopmentGoals.map(goal => {
                // Filtra os documentos que pertencem ao ano atual.
                const documentsByYear = documents.filter(doc => String(doc.publicado) === publicationYear);
                let goalValue = 0;

                // Calcula o valor das barras. Se `isCounting` for true, conta os documentos. Caso contrário, soma as probabilidades.
                if (isCounting) {
                    goalValue = documentsByYear.filter(doc => {
                        const goalData = doc[goal] as unknown as { normalizedSize: number };
                        return goalData?.normalizedSize > 0; // Verifica se a probabilidade é maior que zero.
                    }).length;
                } else {
                    goalValue = d3.sum(documentsByYear, doc => {
                        const goalData = doc[goal] as unknown as { normalizedSize: number };
                        return goalData?.normalizedSize || 0; // Soma as probabilidades.
                    });
                }

                // Retorna os dados formatados para cada ano e ODS.
                return {
                    goal,
                    year: String(publicationYear),
                    value: goalValue,
                };
            })
        );

        // Define as margens do gráfico.
        const chartMargins = { top: 20, right: 150, bottom: 80, left: 100 };

        // Calcula a largura e altura do gráfico, considerando as margens e o modo expandido.
        const chartWidth = (isExpanded ? 1200 : 900) - chartMargins.left - chartMargins.right;
        const chartHeight = (isExpanded ? 800 : 600) - chartMargins.top - chartMargins.bottom;

        // Configura a escala para o eixo X (anos).
        const xAxisScaleYears = d3.scaleBand()
            .domain(publicationYears)
            .rangeRound([0, chartWidth])
            .paddingInner(0.1);

        // Configura a escala para o agrupamento secundário no eixo X (ODS).
        const xAxisScaleGoals = d3.scaleBand()
            .domain(sustainableDevelopmentGoals)
            .rangeRound([0, xAxisScaleYears.bandwidth()])
            .padding(0.05);

        // Configura a escala para o eixo Y, baseada nos valores máximos do conjunto de dados.
        const yAxisScale = d3.scaleLinear()
            .domain([0, d3.max(chartData, dataPoint => dataPoint.value) || 0])
            .nice()
            .rangeRound([chartHeight, 0]);

        // Define a escala de cores, atribuindo uma cor diferente para cada ODS.
        const colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(sustainableDevelopmentGoals);

        // Adiciona um grupo ao SVG para posicionar o gráfico considerando as margens.
        const chartGroup = svgCanvas.append('g')
            .attr('transform', `translate(${chartMargins.left}, ${chartMargins.top})`);

        // Renderiza os grupos de barras (para cada ano) e desenha as barras de cada ODS dentro dos grupos.
        chartGroup.selectAll('g')
            .data(publicationYears)
            .join('g')
            .attr('transform', year => `translate(${xAxisScaleYears(year) ?? 0},0)`)
            .selectAll('rect')
            .data(year => sustainableDevelopmentGoals.map(goal => {
                const value = chartData.find(item => item.goal === goal && item.year === year)?.value || 0;
                return { year, value, goal };
            }))
            .join('rect')
            .attr('x', dataPoint => xAxisScaleGoals(dataPoint.goal) ?? 0)
            .attr('y', dataPoint => yAxisScale(dataPoint.value))
            .attr('width', xAxisScaleGoals.bandwidth())
            .attr('height', dataPoint => yAxisScale(0) - yAxisScale(dataPoint.value))
            .attr('fill', dataPoint => colorScale(dataPoint.goal))
            .style('opacity', dataPoint =>
                activeYears.includes(dataPoint.year) && activeGoals.includes(dataPoint.goal) ? 1 : 0.3
            );

        // Cria o eixo X baseado nos anos.
        const xAxis = d3.axisBottom(xAxisScaleYears).tickSizeOuter(0);

        // Cria o eixo Y baseado nos valores calculados.
        const yAxis = d3.axisLeft(yAxisScale).ticks(10);

        // Renderiza o eixo X na parte inferior do gráfico.
        chartGroup.append('g')
            .attr('transform', `translate(0,${chartHeight})`) // Posiciona o eixo X na parte inferior do gráfico.
            .call(xAxis) // Renderiza o eixo X baseado na escala configurada.
            .selectAll("text") // Seleciona todas as labels do eixo X.
            .style("text-anchor", "start") // Ajusta o alinhamento das labels para o início (ângulo de 45 graus requer isso).
            .attr("dx", "0.8em") // Move as labels horizontalmente para a direita.
            .attr("dy", "0.15em") // Move as labels verticalmente levemente para baixo.
            .attr("transform", "rotate(45)"); // Rotaciona as labels em 45 graus.


        // Renderiza o eixo Y na lateral esquerda do gráfico.
        chartGroup.append('g')
            .call(yAxis);

        // Adiciona o título ao eixo Y.
        chartGroup.append('text')
            .attr('transform', `rotate(-90)`)
            .attr('x', -chartHeight / 2)
            .attr('y', -chartMargins.left / 2)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('font-size', '14px')
            .text(isCounting ? 'Contagem' : 'Intensidade');

        // Adiciona o grupo de legendas à direita do gráfico.
        const legendGroup = svgCanvas.append('g')
            .attr('transform', `translate(${chartMargins.left + chartWidth + 30}, ${chartMargins.top})`);

        // Adiciona os retângulos coloridos da legenda (um para cada ODS).
        legendGroup.selectAll('rect')
            .data(sustainableDevelopmentGoals)
            .join('rect')
            .attr('x', 0)
            .attr('y', (goal, index) => index * 20)
            .attr('width', 15)
            .attr('height', 15)
            .attr('fill', goal => colorScale(goal))
            .style('cursor', 'pointer')
            .style('opacity', goal => (activeGoals.includes(goal) ? 1 : 0.3))
            .on('click', (event, goal) => toggleGoalVisibility(goal));

        // Adiciona os textos das legendas ao lado dos retângulos coloridos.
        legendGroup.selectAll('text')
            .data(sustainableDevelopmentGoals)
            .join('text')
            .attr('x', 20)
            .attr('y', (goal, index) => index * 20 + 12)
            .text(goal => goal)
            .attr('fill', '#000')
            .attr('font-size', '12px')
            .style('cursor', 'pointer')
            .style('opacity', goal => (activeGoals.includes(goal) ? 1 : 0.3))
            .on('click', (event, goal) => toggleGoalVisibility(goal));
    };


    const toggleYearVisibility = (year: string) => {
        setActiveYears(prev =>
            prev.includes(year)
                ? prev.filter(y => y !== year)
                : [...prev, year]
        );
    };

    const toggleGoalVisibility = (goal: string) => {
        setActiveGoals(prev =>
            prev.includes(goal)
                ? prev.filter(g => g !== goal)
                : [...prev, goal]
        );
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleTipoGrafico = () => {
        setIsCounting(!isCounting);
    };

    const handleDownload = () => {
        if (!chartElementRef.current) return;

        const svgElement = chartElementRef.current;
        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(svgElement);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const img = new Image();

        canvas.width = svgElement.clientWidth;
        canvas.height = svgElement.clientHeight;

        img.onload = () => {
            context?.drawImage(img, 0, 0);
            const link = document.createElement('a');
            link.download = 'chart.png';
            link.href = canvas.toDataURL('image/png');
            link.click();
        };

        img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Box>
        );
    }

    return (
        <Box>
            <Box display="flex" justifyContent="left">
                <ButtonGroup size="sm" isAttached variant="outline" colorScheme="blue">
                    <Button onClick={toggleTipoGrafico} isActive={isCounting}>
                        Gráfico de Contagem
                    </Button>
                    <Button onClick={toggleTipoGrafico} isActive={!isCounting}>
                        Gráfico de Intensidade
                    </Button>
                    <Button onClick={() => setChartType('ods')} isActive={chartType === 'ods'}>
                        Gráfico por ODS
                    </Button>
                    <Button onClick={() => setChartType('year')} isActive={chartType === 'year'}>
                        Gráfico por Ano
                    </Button>
                    <Button onClick={handleDownload}>
                        Baixar Gráfico
                    </Button>
                    <Button onClick={toggleExpand}>
                        {isExpanded ? 'Reduzir' : 'Ampliar'}
                    </Button>
                </ButtonGroup>
            </Box>
            <Box width="80%" height="90%">
                <svg
                    ref={chartElementRef}
                    viewBox={`0 0 ${isExpanded ? 1200 : 900} ${isExpanded ? 1200 : 900}`}
                    style={{ width: '100%', height: '100%' }}
                />
            </Box>
        </Box>
    );
};

export default GroupedBarChart;

