import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import fetchData from "../../utils/fetchData";
import LoadingOverlay from "../../utils/LoadingOverlay";
import config from "../../config";

type ChangePasswordForm = {
  newPassword: string;
  confirmPassword: string;
};

const validatePassword = (password: string): string | true => {
  const minLength = 8;
  const maxLength = 16;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (password.length < minLength) {
    return `A senha deve ter pelo menos ${minLength} caracteres.`;
  }
  if (password.length > maxLength) {
    return `A senha deve ter no máximo ${maxLength} caracteres.`;
  }
  if (!hasUpperCase) {
    return "A senha deve conter pelo menos uma letra maiúscula.";
  }
  if (!hasLowerCase) {
    return "A senha deve conter pelo menos uma letra minúscula.";
  }
  if (!hasNumber) {
    return "A senha deve conter pelo menos um número.";
  }
  if (!hasSpecialChar) {
    return "A senha deve conter pelo menos um caractere especial.";
  }

  return true;
};

const ChangePasswordModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true); // Inicia com o modal aberto
  const toast = useToast();
  const navigate = useNavigate(); // Hook para redirecionar o usuário
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm<ChangePasswordForm>();

  const newPassword = watch("newPassword");

  useEffect(() => {
    setIsOpen(true); // Garante que o modal esteja aberto ao montar o componente
  }, []);

  const onSubmit = async (data: ChangePasswordForm) => {
    LoadingOverlay.addOverlay();
    try {
      const url = `${config.apiUrl}/users`;
      await fetchData(url, "patch", toast, { senha: data.newPassword });

      setIsOpen(false); // Fecha o modal após sucesso
      reset();
      toast({
        title: "Senha alterada com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/login"); // Redireciona para login após sucesso
    } finally {
      LoadingOverlay.removeOverlay();
    }
  };

  const handleClose = () => {
    setIsOpen(false); // Fecha o modal
    reset(); // Reseta o formulário
    navigate("/login"); // Redireciona para login ao fechar
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar Senha</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.newPassword} mb={4}>
              <FormLabel>Nova Senha</FormLabel>
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "A nova senha é obrigatória.",
                  validate: validatePassword,
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    placeholder="Digite a nova senha"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.newPassword && errors.newPassword.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.confirmPassword} mb={4}>
              <FormLabel>Confirme a Nova Senha</FormLabel>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "A confirmação da senha é obrigatória.",
                  validate: (value) =>
                    value === newPassword || "As senhas não coincidem.",
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    placeholder="Confirme a nova senha"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.confirmPassword && errors.confirmPassword.message}
              </FormErrorMessage>
            </FormControl>

            <Button colorScheme="blue" type="submit" width="full" mt={4}>
              Alterar Senha
            </Button>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
