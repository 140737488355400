import React from "react";
import { Box, Text } from "@chakra-ui/react";
import TooltipButton from "../buttons/IconButtonWithTooltip";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ProjectDetailsProps {
    data: any[]; // Lista de projetos
    selectedProjectId: number | null; // ID do projeto selecionado
    projectButtonConfig: any[]; // Configuração dos botões do projeto
    documentButtonConfig: any[]; // Configuração dos botões do documento
    onAddProject: () => void; // Função chamada ao adicionar um projeto
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
    data,
    selectedProjectId,
    projectButtonConfig,
    documentButtonConfig,
    onAddProject,
}) => {
    if (!selectedProjectId) {
        return (
            <Box textAlign="center" mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                    Selecione um projeto na lista para visualizar os detalhes, ou inclua um novo, clicando no botão abaixo
                </Text>
                <TooltipButton
                    label="Incluir projeto"
                    icon={faPlus}
                    onClick={onAddProject}
                />
            </Box>
        );
    }

    const selectedProject = data.find((projeto) => projeto.id_projeto === selectedProjectId);

    if (!selectedProject) {
        return (
            <Text fontSize="lg" fontWeight="bold">
                Projeto não encontrado.
            </Text>
        );
    }

    return (
        <Box
            key={selectedProject.id_projeto}
            mt={2}
            p={2}
            border="1px solid" 
            borderColor="gray.400"
            borderRadius="md" 
            bg="white" 
            w="100%"
        >
            {/* Detalhes do Projeto */}
            <Text fontSize="lg" mb={4}>
                <strong>ID:</strong> {selectedProjectId}&nbsp;&nbsp;
                <strong>Título do Projeto: </strong>
                {selectedProject.titulo}
            </Text>
            <Text fontSize="lg" mb={4}>
                <strong>Ano inicial: </strong>
                {selectedProject.ano_inicial} &nbsp;&nbsp; | &nbsp;&nbsp;
                <strong>Ano final: </strong>
                {selectedProject.ano_final} &nbsp;&nbsp; | &nbsp;&nbsp;
                <strong>Total de Documentos: </strong>
                {selectedProject.documentos.length}
            </Text>
            {/* Contêiner para todos os botões */}
            <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
                {/* Botões principais */}
                {selectedProject.usuario && (
                    <Box p={4} borderColor="gray.400" borderRadius="md" bg="gray.100">
                        <Text fontSize="lg" mb={4} align="center">
                            <strong>Ações no projeto</strong>
                        </Text>
                        {projectButtonConfig
                            .filter((btn) => btn.condition) // Filtrar botões com condições verdadeiras
                            .map((btn) => (
                                <TooltipButton
                                    key={btn.id} // Usando `id` como chave
                                    label={btn.label}
                                    icon={btn.icon}
                                    colorScheme={btn.colorScheme}
                                    onClick={btn.onClick}
                                />
                            ))}
                    </Box>
                )}
                {/* Botões do bloco selectedProjectId */}
                <Box pl={12} p={4} ml="auto" borderColor="gray.400" borderRadius="md" bg="gray.100">
                    <Text fontSize="lg" mb={4} align="center">
                        <strong>Ações no documento</strong>
                    </Text>
                    {documentButtonConfig
                        .filter((btn) => btn.condition) // Filtrar botões com condições verdadeiras
                        .map((btn) => (
                            <TooltipButton
                                key={btn.id}
                                label={btn.label}
                                icon={btn.icon}
                                colorScheme={btn.colorScheme}
                                onClick={btn.onClick}
                            />
                        ))
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default ProjectDetails;
