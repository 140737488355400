import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faEdit, faTrash, faChartPie, faUpload, faDiagramProject } from "@fortawesome/free-solid-svg-icons";
import { Dispatch, SetStateAction } from "react";

interface ButtonConfig {
  id: number,
  label: string; // Texto do botão
  icon: IconProp; // Ícone do FontAwesome
  onClick: () => void; // Função executada ao clicar
  colorScheme?: string; // Esquema de cores do botão (opcional)
  style?: React.CSSProperties; // Estilo inline opcional
  condition: boolean; // Condição para exibir o botão
}

export const getProjectButtonConfig = (
  projeto: any,
  setAction: Dispatch<SetStateAction<"edit" | "delete" | "create">>, // Ajustar o tipo
  onOpenProjeto: () => void,
  setIsGrafosOpen: (open: boolean) => void
) => [
  {
    id:1,
    label: "Incluir projeto",
    icon: faPlus,
    colorScheme: "green",
    onClick: () => {
      setAction("create");
      onOpenProjeto();
    },
    condition: projeto.usuario,
  },
  {
    id:2,
    label: "Editar projeto",
    icon: faEdit,
    colorScheme: "blue",
    onClick: () => {
      setAction("edit");
      onOpenProjeto();
    },
    condition: projeto.usuario,
  },
  {
    id:3,
    label: "Excluir projeto",
    icon: faTrash,
    colorScheme: "red",
    onClick: () => {
      setAction("delete");
      onOpenProjeto();
    },
    condition: projeto.usuario,
  },
  {
    id:4,
    label: "Relatórios",
    icon: faChartPie,
    colorScheme: "blue",
    onClick: () => setIsGrafosOpen(true),
    condition: projeto.usuario && !projeto.descritor,
  },
];

export const getDocumentButtonConfig = (
  projeto: any,
  selectedDocumentId: string | number | null,
  setAction: Dispatch<SetStateAction<"edit" | "delete" | "create">>, // Ajuste no tipo
  setSelectedDocumentId: Dispatch<SetStateAction<number | null>>,
  onOpenDocumento: () => void,
  setIsFileUpOpen: (open: boolean) => void,
  setIsModalOpenSobre: (open: boolean) => void
): ButtonConfig[] => [
  {
    id:1,
    label: "Incluir documento",
    icon: faPlus,
    colorScheme: "green",
    onClick: () => {
      setAction("create");
      onOpenDocumento();
    },
    condition: projeto.usuario,
  },
  {
    id:2,
    label: "Importar documentos Excel",
    icon: faUpload,
    colorScheme: "purple",
    onClick: () => setIsFileUpOpen(true),
    condition: projeto.usuario || !projeto.documentos,
  },
  {
    id:3,
    label: "Editar documento",
    icon: faEdit,
    colorScheme: "blue",
    //style: { backgroundColor: "blue", display: "inline-block" },
    onClick: () => {
      setAction("edit");
      onOpenDocumento();
    },
    condition: selectedDocumentId !== null && projeto.usuario,
  },
  {
    id:4,
    label: "Excluir documento",
    icon: faTrash,
    colorScheme: "red",
    //style: { backgroundColor: "red", display: "inline-block" },
    onClick: () => {
      setAction("delete");
      onOpenDocumento();
    },
    condition: selectedDocumentId !== null && projeto.usuario,
  },
  {
    id:5,
    label: "Grafos",
    icon: faDiagramProject,
    colorScheme: "blue",
    //style: { backgroundColor: "blue", display: "inline-block" },
    onClick: () => setIsModalOpenSobre(true),
    condition: selectedDocumentId !== null,
  },
];
