import React, { useState } from 'react';
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalBody, ModalFooter, ModalCloseButton, Select,
    Progress,
    Box,
    useToast
} from "@chakra-ui/react";
import DataTable from 'react-data-table-component'; // Biblioteca para a tabela
import * as XLSX from 'xlsx';
import config from '../../config';
import LoadingOverlay from '../../utils/LoadingOverlay';
import fetchData from '../../utils/fetchData';
const Papa = require('papaparse');

interface FileUploadProps {
    isOpen: boolean;
    onClose: () => void;
    id_projeto: number;
}

interface RowData {
    [key: string]: any;
}

interface Data {
    id_projeto: number;
    anoPublicacao: string;
    titulo: string;
    discente: string;
    orientador: string;
    resumo: string;
    palavraChave: string[];
}

const FileUpload: React.FC<FileUploadProps> = ({ isOpen, onClose, id_projeto }) => {
    const toast = useToast();
    const [file, setFile] = useState<File | null>(null);
    const [columns, setColumns] = useState<string[]>([]);
    const [rows, setRows] = useState<any[][]>([]);
    const [mapping, setMapping] = useState({
        anoPublicacao: -1,
        titulo: -1,
        discente: -1,
        orientador: -1,
        resumo: -1,
        palavraChave: -1
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFile(file);
            processFile(file);
        }
    };

    const processFile = (file: File) => {
        const reader = new FileReader();
        const isCsv = file.name.endsWith('.csv');

        reader.onload = (event) => {
            const data = event.target?.result;
            if (isCsv && typeof data === 'string') {
                const parsed = Papa.parse(data, { header: false });
                const rows = parsed.data;
                if (rows && rows.length > 0) {
                    console.log('Columns:', rows[0]);
                    console.log('Rows:', rows.slice(1));
                    setColumns(rows[0]);
                    setRows(rows.slice(1));
                } else {
                    setColumns([]);
                    setRows([]);
                }
            } else {
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                if (jsonData && jsonData.length > 1) {
                    console.log('Columns:', jsonData[0]);
                    console.log('Rows:', jsonData.slice(1));
                    setColumns(jsonData[0]);
                    setRows(jsonData.slice(1));
                } else {
                    setColumns([]);
                    setRows([]);
                }
            }
        };

        if (isCsv) {
            reader.readAsText(file);
        } else {
            reader.readAsBinaryString(file);
        }
    };

    const handleSubmit = async () => {
        if (!file || rows.length === 0) {
            alert('Nenhum dado válido para enviar.');
            return;
        }
        setIsProcessing(true);
        LoadingOverlay.addOverlay();
        try {
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                const data = buildData(row);

                if (!isDataValid(data)) {
                    console.warn(`Dados inválidos na linha ${i + 1}`);
                    continue;
                }

                const documento = await fetchData(`${config.apiUrl}/documento`, 'post', toast, data);
                let caminho = `${config.apiUrl}/process/classify/${1}/${documento.id_documento}/O`;
                await fetchData(caminho, 'get', toast);
                caminho = `${config.apiUrl}/process/classify/${2}/${documento.id_documento}/O`;
                await fetchData(caminho, 'get', toast);
                setProgress(((i + 1) / rows.length) * 100);
            }
        } finally {
            LoadingOverlay.removeOverlay();
            setIsProcessing(false);
            onClose();
        }
    };

    const buildData = (row: RowData): Data => {
        const data: Data = {
            anoPublicacao: mapping.anoPublicacao !== -1 && row[mapping.anoPublicacao] !== undefined
                ? row[mapping.anoPublicacao]?.toString().trim()
                : '',
            titulo: mapping.titulo !== -1 && row[mapping.titulo] !== undefined
                ? row[mapping.titulo].toString().trim()
                : '',
            discente: mapping.discente !== -1 && row[mapping.discente] !== undefined
                ? row[mapping.discente]?.toString().trim()
                : '',
            orientador: mapping.orientador !== -1 && row[mapping.orientador] !== undefined
                ? row[mapping.orientador]?.toString().trim()
                : '',
            resumo: mapping.resumo !== -1 && row[mapping.resumo] !== undefined
                ? row[mapping.resumo]?.toString().trim()
                : '',
            palavraChave: mapping.palavraChave !== -1 && row[mapping.palavraChave] !== undefined
                ? row[mapping.palavraChave]?.toString().split(/[.,;]/).map((word: string) => word.trim()).filter(Boolean)
                : [],
            id_projeto: id_projeto
        };
        return data;
    };

    const isDataValid = (data: Data): boolean => {
        const valid = Boolean(data.anoPublicacao && data.titulo && data.resumo);
        if (!valid) {
            console.error('Dados inválidos:', data);
        }
        return valid;
    };

    const fields = [
        { name: "titulo", label: "Título" },
        { name: "anoPublicacao", label: "Ano de Publicação" },
        { name: "discente", label: "Discente" },
        { name: "orientador", label: "Orientador" },
        { name: "resumo", label: "Resumo" },
        { name: "palavraChave", label: "Palavras-chave" }
    ];

    const tableColumns = columns.map((col, index) => ({
        name: (
            <Box>
                {/* Select Acima do Título */}
                <Select
                    size="sm"
                    value={
                        Object.entries(mapping).find(([key, val]) => val === index)?.[0] || ""
                    }
                    onChange={(e) =>
                        setMapping((prev) => ({
                            ...prev,
                            [e.target.value]: index,
                        }))
                    }
                >
                    <option value="" disabled>
                        -- Atribuir --
                    </option>
                    {fields.map((field) => (
                        <option key={field.name} value={field.name}>
                            {field.label}
                        </option>
                    ))}
                </Select>
                {/* Título da Coluna */}
                <Box mt="2">{col}</Box>
            </Box>
        ),
        selector: (row: { [x: string]: any; }) => row[col], // Usa o nome da coluna para acessar os valores
    }));

    const tableData = rows.map((row) => {
        const rowData: Record<string, any> = {};
        columns.forEach((col, index) => {
            rowData[col] = row[index]; // Mapeia cada coluna para o valor da linha
        });
        return rowData;
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" closeOnOverlayClick={!isProcessing}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Carregar Arquivo</ModalHeader>
                <ModalCloseButton isDisabled={isProcessing} />
                <ModalBody>
                    <input
                        type="file"
                        accept=".csv,.xlsx"
                        onChange={handleFileChange}
                        disabled={isProcessing}
                        aria-label="Selecione o arquivo"
                    />
                    {isProcessing && (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            {/* Barra de progresso estilizada */}
                            <div style={{ marginBottom: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2B6CB0' }}>
                                🚀 Processando... {progress.toFixed(0)}%
                            </div>
                            <Progress
                                value={progress}
                                size="lg"
                                colorScheme="blue"
                                hasStripe
                                isAnimated
                                borderRadius="8px"
                                boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
                            />
                            <div style={{ marginTop: '10px', fontSize: '14px', color: '#4A5568' }}>
                                Por favor, aguarde enquanto processamos o arquivo.
                            </div>
                        </div>
                    )}
                    <Box
                        maxHeight="100%"
                        maxWidth="100%"
                        overflow="auto"
                        border="1px solid #e2e8f0"
                        borderRadius="8px"
                    >
                        {columns.length > 0 && (
                            <DataTable
                                title="Dados do Arquivo"
                                columns={tableColumns}
                                data={tableData}
                                pagination
                                paginationComponentOptions={{
                                    rowsPerPageText: "Linhas por página",
                                    rangeSeparatorText: "de",
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: "Todos",
                                }}
                            />
                        )}
                        {isProcessing && (
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Progress value={progress} size="lg" colorScheme="blue" hasStripe isAnimated />
                            </div>
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        onClick={handleSubmit}
                        disabled={isProcessing}
                    >
                        Confirmar
                    </Button>
                    <Button
                        variant="ghost"
                        onClick={onClose}
                        disabled={isProcessing}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default FileUpload;
