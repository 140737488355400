import React from "react";
import { Box, Text } from "@chakra-ui/react";
import PalavrasChave from "./PalavrasChave"; // Ajuste o caminho do import conforme necessário

interface DocumentDetailsProps {
  data: any[]; // Estrutura do array de projetos contendo os documentos
  selectedDocumentId: number | null; // ID do documento selecionado
  Descritor: number; // Valor do descritor para exibição condicional
}

const DocumentDetails: React.FC<DocumentDetailsProps> = ({
  data,
  selectedDocumentId,
  Descritor,
}) => {
  if (!selectedDocumentId) return null;

  return (
    <>
      {data
        .flatMap((projeto) => projeto.documentos) // Obtém todos os documentos
        .filter((doc) => doc.id_documento === selectedDocumentId) // Filtra pelo documento selecionado
        .map((documento) => (
          <Box
            key={documento.id_documento}
            mt={4}
            p={4}
            bg="white"
            overflowY="auto"
            border="1px solid" 
            borderColor="gray.400"
            borderRadius="md" 
            w="100%"
          >
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Detalhes do Documento - ID: {documento.id_projeto}/{documento.id_documento} 
            </Text>
            <Text mb={2}>
              <strong>Título:</strong> {documento.titulo}
            </Text>
            {Descritor === 0 && (
              <>
                <Text mb={2}>
                  <strong>Discente:</strong> {documento.discente}
                </Text>
                <Text mb={2}>
                  <strong>Orientador:</strong> {documento.orientador}
                </Text>
              </>
            )}
            <Text mb={2}>
              <strong>Ano de Publicação:</strong> {documento.anoPublicacao}
            </Text>
            <Text mb={2}>
              <strong>Resumo:</strong> {documento.resumo}
            </Text>
            <Text mb={2}>
              <strong>Palavras-Chave:</strong>
              <PalavrasChave
                palavrasIniciais={
                  Array.isArray(documento.palavraChave)
                    ? documento.palavraChave
                    : (documento.palavraChave || "")
                        .split(/[.,;]/) // Divide usando delimitadores ".", "," ou ";"
                        .map((palavra: string) => palavra.trim()) // Remove espaços extras
                }
                isEditing={false} // Permite edição apenas para criar ou editar
                onPalavrasChange={() => {}} // Atualiza o valor no Controller
                tituloAtributo={"Palavras-Chave"}
                soLista={true}
              />
            </Text>
          </Box>
        ))}
    </>
  );
};

export default DocumentDetails;
